import * as React from "react"
import Layout from "../components/layout"
import { Container, Row, Col} from "react-bootstrap"
import { Helmet } from 'react-helmet';

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Unternehmensgruppe Urbas</title>
      </Helmet>
      <Container>
        <Row>
          <Col md={12} className="overflow-hidden" style={{height: '25vh'}}>
            <div className="bg-dark-primary parallelogram-header1-sub">
              <div className="unskew-header1-sub">
                <div id="rect-sub">
                  <div className="p-3">
                    <h1>
                      404
                      <br />
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="pt-3 pb-5 p-0 ">
        <div className="bg-ultralight">
          <div className="container-fluid py-4 text-left" style={{maxWidth: 1000}}>
            <Row>
              <Col>
                <h4>Die angeforderte Seite wurde nicht gefunden!</h4>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
